import { useEthers } from "@usedapp/core";




export const Addresses = {
  FLV: '0x2b2894b4c2FCFdEEaBB8cDe8964880110859AED6',    // MOCK TEST FLV
  NSM: '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F', 
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',

  nsm: {
    Pair: '0x357f9cd8f2749A31119C3E32729965CA56f4cBd8',   // MAINNET BSC
    Migrate: '0xF1a4550Cd1d06b1e4D7c34fE49dA26D2E6253C55'
  },
  
  NotTools: {
    LPDataV3: '0xfffff8fE58810B693DA405bEfab496E64EC1E40E',
    ERC20Data: '0xD00DAD8e9680d863bf178fC74F6F58deA1bcc34b',
    MultiSend: '0x7117113772b3e95BeFAC2d5a183e60070465CE95',
    BalancesV2: '0xaaaa1D1Ba4572B419584e2C30a357A890172B32C',
    LivePriceV3: '0x4444434a5917ae1c00FE51705a8C9C4B36887cde',
    FactoryCreate2: '0x04396f9531c5f3cd0fac735f661a987e7e08fc87',
  },

  Pancake: {
    Factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    Router: '0x10ed43c718714eb63d5aa57b78b54704e256024e',// MAINNET BSC
    //Router: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',// TESTNET BSC
  }
}

