
import { Section, SectionRow } from '../components/base/base'
import { Title } from '../typography/Title'
import { TransactionApproveMigrateNSM } from '../components/Transactions/MigrateTx'

export function Approve() {

  return (
        <Section>
          <SectionRow>
            <Title>Approve</Title>
          </SectionRow>
            <TransactionApproveMigrateNSM />
        </Section>
  )
}
