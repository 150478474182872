import React from 'react';
import styled from 'styled-components';
import {Sizes} from '../global/styles';
import {AccountButton} from './account/AccountButton';
import {Images} from './Images';
import {Section} from './base/base';
import {ContentRow} from './base/base';

export function PageHeader() {
	return (
		<Header>
			<Section>
				<ContentRow>
					<img src={Images.logo} width="200px" />
				</ContentRow>
				<AccountButton />
			</Section>
		</Header>
	);
}

const Space = styled.header`
	display: flex;
	position: relative;
	height: 100%;
	padding: 40px;
	white-space: nowrap;
`;

const Header = styled.header`
	display: flex;
	position: fixed;
	top: 20px;
  left: 20px;
  right: 20px:
	align-items: center;
	width: 100%;
	height: ${Sizes.headerHeight};
	z-index: 100;
`;
