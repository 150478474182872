import {formatEther} from '@ethersproject/units';
import {TransactionStatus, useEthers, transactionErrored} from '@usedapp/core';
import React, {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import {TextBold} from '../../typography/Text';
import {ContentBlock} from '../base/base';
import {Button} from '../base/Button';
import {BorderRad, Colors} from '../../global/styles';
import {BigNumber} from 'ethers';
import {SpinnerIcon, CheckIcon, ExclamationIcon} from './Icons';

import {AnimatePresence, motion} from 'framer-motion';

const formatter = new Intl.NumberFormat('en-us', {
	minimumFractionDigits: 4,
	maximumFractionDigits: 4,
});

const formatBalance = (balance: BigNumber | undefined) => formatter.format(parseFloat(formatEther(balance ?? BigNumber.from('0'))));

interface StatusBlockProps {
	color: string;
	text: string;
	icon: ReactElement;
}

const StatusBlock = ({color, text, icon}: StatusBlockProps) => (
	<InformationRow layout initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 50}} key={text}>
		<IconContainer style={{fill: color}}>{icon}</IconContainer>
		<div style={{color: color, textAlign: 'center'}}>{text}</div>
	</InformationRow>
);
interface StatusAnimationProps {
	transaction: TransactionStatus;
}

export const StatusAnimation = ({transaction}: StatusAnimationProps) => {
	const [showTransactionStatus, setShowTransactionStatus] = useState(false);
	const [timer, setTimer] = useState(
		setTimeout(() => {
			void 0;
		}, 1)
	);

	useEffect(() => {
		setShowTransactionStatus(true);
		clearTimeout(timer);
		if (transaction.status !== 'Mining') setTimer(setTimeout(() => setShowTransactionStatus(false), 5000));
	}, [transaction]);

	return (
		<AnimationWrapper>
			<AnimatePresence initial={false} exitBeforeEnter>
				{showTransactionStatus && transactionErrored(transaction) && <StatusBlock color={Colors.Red['400']} text={transaction?.errorMessage || ''} icon={<ExclamationIcon />} key={transaction?.chainId + transaction.status} />}
				{showTransactionStatus && transaction.status === 'Mining' && <StatusBlock color="black" text="Transaction is being mined" icon={<SpinnerIcon />} key={transaction?.chainId + transaction.status} />}
				{showTransactionStatus && transaction.status === 'Success' && <StatusBlock color="green" text="Transaction successful" icon={<CheckIcon />} key={transaction?.chainId + transaction.status} />}
			</AnimatePresence>
		</AnimationWrapper>
	);
};

interface InputComponentProps {
	send: (value: string) => void;
	transaction: TransactionStatus;
}

const InputComponent = ({transaction, send}: InputComponentProps) => {
	const {account} = useEthers();
	const [value, setValue] = useState('0');
	const [disabled, setDisabled] = useState(false);

	const onClick = () => {
		setDisabled(true);
		send(value);
	};

	useEffect(() => {
		if (transaction.status !== 'Mining') {
			setDisabled(false);
			setValue('0');
		}
	}, [transaction]);

	return (
		<InputRow>
			<SmallButton disabled={!account || disabled} onClick={onClick}>
				Send
			</SmallButton>
		</InputRow>
	);
};

interface TransactionFormProps {
	send: (value: string) => void;
	title: string;
	transaction: TransactionStatus;
}

export const ContractCallSendNoInputs = ({send, title, transaction}: TransactionFormProps) => (
	<SmallContentBlock>
		<TitleRow>
			<CellTitle>{title}</CellTitle>
		</TitleRow>
		{<LabelRow></LabelRow>}
		<InputComponent transaction={transaction} send={send} />
		<StatusAnimation transaction={transaction} />
	</SmallContentBlock>
);

const SmallButton = styled(Button)`
	background-color: ${Colors.flavors.background};
	display: flex;
	justify-content: center;
	min-width: 200px;
	height: unset;
	padding: 8px 24px;

	&:disabled {
		color: ${Colors.Gray['600']};
		cursor: unset;
	}
  &:hover{
	  background-color: ${Colors.flavors.main.bysantine};
  }

	&:disabled:hover,
	&:disabled:focus {
		background-color: unset;
		color: unset;
	}
`;

const CellTitle = styled(TextBold)`
	font-size: 18px;
`;

const InputRow = styled.div`
	display: flex;
	margin: 0 auto;
	color: ${Colors.Gray['600']};
	align-items: center;
	border: ${Colors.Gray['300']} 1px solid;
	border-radius: ${BorderRad.m};
	overflow: hidden;
`;

const LabelRow = styled.div`
	display: flex;
	justify-content: center;
	margin: 32px 0 24px 0;
`;

const TitleRow = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	border-bottom: ${Colors.Gray['300']} 1px solid;
	padding: 16px;
`;

const SmallContentBlock = styled(ContentBlock)`
	padding: 0;
`;

const IconContainer = styled.div`
	margin-right: 15px;
	height: 40px;
	width: 40px;
	float: left;
`;

const InformationRow = styled(motion.div)`
	height: 60px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: auto;
`;

const AnimationWrapper = styled.div`
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	margin: 10px;
`;
