import styled from 'styled-components';

import {useEthers, useTokenBalance} from '@usedapp/core';
import { ContentBlock, ContentRow, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import {TextInline, TextInlineConsolas} from '../typography/Text';
import {Addresses} from '../components/contracts/Addresses';
import {TransactionDepositNSM} from '../components/Transactions/MigrateTx';
import {TransactionDepositMAXNSM} from '../components/Transactions/MigrateTx';
import { helpers } from '../components/helpers/helpers';
import { GetInfo } from '../hooks/GetInfo';

export function Deposit() {
	const {account} = useEthers();
	const userBalanceNSM = useTokenBalance(Addresses.NSM, account);
  const {
     claimsEnabled_,
     depositsEnabled_
    } = GetInfo();
  
  
  
	
	
	return (
				<Section>
					<SectionRow>
						<Title>Deposit</Title>
					</SectionRow>          
          <SectionRow>
            {/*
              {!depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}
              
              {depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}

              */}
          </SectionRow>
					<SectionRow>
						<ContentBlock>
							{userBalanceNSM && (
								<ContentRow>
									<Label>NotSafeMoon Balance</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(userBalanceNSM, 9)))}</TextInlineConsolas> <Label>NSM</Label>
								</ContentRow>
							)}

{/*

              {typeof(claimsEnabled_)=='undefined'?claimsEnabled_:'' && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}


              {claimsEnabled_ && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}


              {!depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}
              
              {depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}
              */}
              {/*<TextInline>You may deposit as few or as many tokens, up to your snapshot balance, as you'd like</TextInline> */}
              
						</ContentBlock>
					</SectionRow>
          
					<SectionRow>
						<TransactionDepositNSM />
					</SectionRow>
          <SectionRow>
						<TransactionDepositMAXNSM />
            </SectionRow>
				</Section>
	);
}

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;
