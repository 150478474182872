

import { utils } from 'ethers'
import React from 'react'
import { useContractFunction, useEthers, useTokenBalance, useTokenAllowance } from '@usedapp/core'

import { TransactionForm } from './TransactionForm'
import { ContractCallSendNoInputs } from './ContractCallSendNoInputs'
import { Addresses } from '../contracts/Addresses'
import { Contracts } from '../contracts/Contracts'


//import { ContractInstance } from '../web3Contracts'
import { TextInline } from '../../typography/Text';

/*
export const GetAllowance = () => {
  const { account } = useEthers()
  const allowance = useTokenAllowance(Addresses.NSM, account, Addresses.nsm.Migrate)
  //console.log(allowance)
  //if(allowance._hex == '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'){
    return ({allowance && <p>allowance</p>})
  //} else {
    //return 'Approval Required'
  //}
  
}
*/
/*
[snapshotBalance_,
  snapshotAmountOutBNB_,
  holderDeposits_,
  remainingNSMdeposit_,
  completedDeposits_,
  maxClaimableFLV,
  currentClaimableFLV,
  claimedFLV_,
  completedClaims_]*/

/*
  export function useTokenAllowance(
    tokenAddress: string | Falsy,
    ownerAddress: string | Falsy,
    spenderAddress: string | Falsy
  ): BigNumber | undefined {
    const [allowance] =
      useContractCall(
        ownerAddress &&
          spenderAddress &&
          tokenAddress && {
            abi: ERC20Interface,
            address: tokenAddress,
            method: 'allowance',
            args: [ownerAddress, spenderAddress],
          }
      ) ?? []
    return allowance
  }
  */

  /*
  [snapshotBalance_,
    snapshotAmountOutBNB_,
    holderDeposits_,
    remainingNSMdeposit_,
    completedDeposits_,
    maxClaimableFLV,
    currentClaimableFLV,
    claimedFLV_,
    completedClaims_]*/


 




export const TransactionDepositNSM = () => {
  const { account } = useEthers()
  const nsmBalance = useTokenBalance(Addresses.NSM, account)

  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'depositSome', { transactionName: 'Deposit Some' })

  const depositNSM = (nsmAmount: string) => {
    send(utils.parseUnits(nsmAmount, 9))
    // this is the contract's input data
    // send(nsmAmount)
  }

  return (
    
    <TransactionForm
      balance={nsmBalance}
      send={depositNSM}
      title="Deposit Some"
      ticker="NSM"
      transaction={state}
    />
  )
}


export const TransactionDepositMAXNSM = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'depositMAX', { transactionName: 'Deposit MAX' })
  const depositMAX = () => {
    send()
  }							
  return (
    <ContractCallSendNoInputs
      send={depositMAX}
      title="Deposit MAX"
      transaction={state}
    />
  )
}







export const TransactionToggleDeposits = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'toggleDeposits_OICM', { transactionName: 'ADMIN: Toggle Deposits' })
  const toggleDeposits = () => {
    send()
  }							
  return (
    <ContractCallSendNoInputs
      send={toggleDeposits}
      title="ADMIN: Toggle Deposits"
      transaction={state}
    />
  )
}


export const TransactionToggleClaims = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'toggleClaims_OICM', { transactionName: 'ADMIN: Toggle Claims' })
  const toggleClaims = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={toggleClaims}
      title="ADMIN: Toggle Claims"
      transaction={state}
    />
  )
}

/*
export const TransactionAcceptIceCreamManOPI = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'acceptIceCreamMan_OPI', { transactionName: 'PENDING ICM: Accept ICM Role' })
  const AcceptIceCreamMan_OPI = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={AcceptIceCreamMan_OPI}
      title="PENDING ICM: Accept ICM Role"
      transaction={state}
    />
  )
}
*/

export const TransactionMigrateClaimedNSM = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'migrateClaimedNSM', { transactionName: 'ADMIN: Migrate Claimed NSM' })
  const migrateClaimedNSM = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={migrateClaimedNSM}
      title="ADMIN: Migrate Claimed NSM"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////

export const SwapOut = () => {
  const { state, send } = useContractFunction(
    Contracts.nsm.Migrate,
    'swapOut',
    { transactionName: 'ADMIN: Swap Out' }
  )
  const swapOut = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={swapOut}
      title="ADMIN: Swap Out"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////



export const TransferContributedBNB = () => {
  const { state, send } = useContractFunction(
    Contracts.nsm.Migrate,
    'transferContributedBNB',
    { transactionName: 'ADMIN: Transfer Contributed BNB' }
  )
  const transferContributedBNB = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={transferContributedBNB}
      title="ADMIN: Transfer Contributed BNB"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////












/*

export const TransactionDepositMAXNSM = () => {
  const { account } = useEthers()
  const nsmBalance = useTokenBalance(Addresses.NSM, account)
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'depositMAX', { transactionName: 'Deposit MAX' })
  const depositMAX = (etherAmount: string) => {
    send({ value: utils.parseEther(etherAmount) })
  }							
  return (
    <ContractCallSendNoInputs
      send={depositMAX}
      title="Deposit MAX"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////
*/
/*

export const TransactionDepositMAXNSM = () => {
  const { account } = useEthers()
  const nsmBalance = useTokenBalance(Addresses.NSM, account)
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'depositMAX', { transactionName: 'Deposit MAX' })
  const depositMAX = (etherAmount: string) => {
    send({ value: utils.parseEther(etherAmount) })
  }							
  return (
    <ContractCallSendNoInputs
      send={depositMAX}
      title="Deposit MAX"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////
*/
/*
export const TransactionDepositMAXNSM = () => {
  const { account } = useEthers()
  const nsmBalance = useTokenBalance(Addresses.NSM, account)
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'depositMAX', { transactionName: 'Deposit MAX' })
  const depositMAX = (etherAmount: string) => {
    send({ value: utils.parseEther(etherAmount) })
  }							
  return (
    <ContractCallSendNoInputs
      send={depositMAX}
      title="Deposit MAX"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////
*/

export const TransactionClaimFLV = () => {
  const { state, send } = useContractFunction(Contracts.nsm.Migrate, 'claim', { transactionName: 'Claim FLV' })

  const claimFLV = () => {
    send()
  }

  return (
    <ContractCallSendNoInputs
      send={claimFLV}
      title="Claim Flavors Token"
      transaction={state}
    />
  )
}


export const TransactionApproveMigrateNSM = () => {
  const { state, send } = useContractFunction(Contracts.NSM, 'approve', { transactionName: 'Approve' })

  const approveNSM = (spender: string) => {
    send(Addresses.nsm.Migrate, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
  }

  return (
    <ContractCallSendNoInputs
      send={approveNSM}
      title="Approve Migration Contract"
      transaction={state}
    />
  )
}


export const ApprovalStatus = () => {  
  const { account } = useEthers()
  const userMigrationApproval = useTokenAllowance(Addresses.NSM,account,Addresses.nsm.Migrate)  
}
