import {ContentBlock, ContentRow, Section, SectionRow} from '../components/base/base';
import {Link} from '../components/base/Link';
import {BigLabel} from '../typography/BigLabel';
import {TextInline} from '../typography/Text';
import {Title} from '../typography/Title';
import { ContentBlockInset } from './MigrationStats';

export function Instructions() {
	return (
		<Section>
			<SectionRow>
				<Title>Migration Instructions</Title>
			</SectionRow>
			<ContentBlock>
				<ContentRow>
					<BigLabel>Step 1.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Connect Wallet</BigLabel>
				</ContentRow>
        <ContentBlockInset>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Click the 'Connect' button to connect your wallet</TextInline>
          </ContentBlockInset>
				<ContentRow>
					<br></br>
					<BigLabel>Step 2.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Check</BigLabel>
				</ContentRow>
        <ContentBlockInset>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll down to 'My Info' and check the numbers reported look accurate.</TextInline>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Much care was taken to ensure the accuracy of the migration.</TextInline>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If something doesn't look right, do not deposit any tokens, and contact us.</TextInline>
          </ContentBlockInset>
				<br></br>
				<ContentRow>
					<BigLabel>Step 3.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Approve</BigLabel>
				</ContentRow>
        <ContentBlockInset>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll to 'Approve' & hit 'send' to approve the migration contract's transfer of your NSM Tokens.</TextInline>
          </ContentBlockInset>
				<br></br>
				<ContentRow>
					<BigLabel>Step 4.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Deposit</BigLabel>
				</ContentRow>
        <ContentBlockInset>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll to 'Deposit' and choose to deposit some or deposit all of your NSM</TextInline>
          </ContentBlockInset>
				<br></br>
				<ContentRow>
					<BigLabel>Step 5.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Claim</BigLabel>
				</ContentRow>
        <ContentBlockInset>
				<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After the Pancake Swap launch, October 24th 2021, click the 'Claim' tab and claim your Flavors Tokens!</TextInline>
          </ContentBlockInset>
				<br></br>
				<ContentRow>
					<BigLabel>Step 6.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Join the Flavors Community</BigLabel>
				</ContentRow>
        
        <ContentBlockInset>
				<h3>
					<button><Link href="https://FlavorsBSC.com">FlavorsBSC.com</Link></button>
				</h3>
				<h3>
					<Link href="https://Flavors.Tools">Flavors.Tools</Link>
				</h3>
				<h3>
					<Link href="https://twitter.com/FlavorsBSC">Twitter</Link>
				</h3>
				<h3>
					<Link href="https://t.me/flavorsBSC">Telegram</Link>
				</h3>
				<h3>
					<Link href="https://discord.com/invite/6QnCxUQv8V">Discord</Link>
				</h3>
				<h3>
					<Link href="https://www.youtube.com/channel/UCSgHGLH-IkUbBIQYfSjVoFA">Youtube</Link>
				</h3>
				<h3>
					<Link href="https://www.instagram.com/flavorsbsc/">Instagram</Link>
				</h3>
				<h3>
					<Link href="https://github.com/FlavorsDeFi">Github</Link>
				</h3>
				<br></br></ContentBlockInset>
			</ContentBlock>
		</Section>
	);
}
