import styled from 'styled-components';

import {useEthers, useTokenBalance} from '@usedapp/core';
import {ContentBlock, ContentRow, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import {TextInlineConsolas, TextInline} from '../typography/Text';
import {Addresses} from '../components/contracts/Addresses';
import {helpers} from '../components/helpers/helpers';
import {GetHolderInfo} from '../hooks/GetMyInfo';
import {BorderRad, Colors} from '../global/styles';
import {Button} from '../components/base/Button';

export function MyInfo() {
	const {account} = useEthers();
	const userBalanceNSM = useTokenBalance(Addresses.NSM, account);
	const openGithub = function () {
		window.open('https://github.com/NotSafeMoon/MigrateNSM', '_blank');
	};

	const {snapshotBalance_, snapshotAmountOutBNB_, holderDeposits_, remainingNSMdeposit_, maxClaimableFLV, currentClaimableFLV, claimedFLV_, remainingClaimableFLV_, completedDeposits_, completedClaims_} = GetHolderInfo(account || '0x0000000000000000000000000000000000000000');

	return (
		<Section>
			<SectionRow>
				<Title>My Info</Title>
			</SectionRow>
			<SectionRow>
				<ContentBlock>
					{userBalanceNSM && (
						<ContentRow>
							<Label>Current Balance:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Math.round(Number(formatUnits(userBalanceNSM, 9))))} NSM</TextInlineConsolas>
						</ContentRow>
					)}

					{snapshotBalance_ && (
						<ContentRow>
							<Label>Snapshot Balance:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(snapshotBalance_, 9)))} NSM</TextInlineConsolas>
						</ContentRow>
					)}

					{snapshotAmountOutBNB_ && (
						<ContentRow>
							<Label>Snapshot Amount Out:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(snapshotAmountOutBNB_, 18)))} BNB</TextInlineConsolas>
							<ContentRow>
								<ContentBlockInset>
										<TextInline>
											<ContentRow>
												<b>'Snapshot Amount Out'</b> is used to calculate how many Flavors Tokens you will receive. This is the exact amount of BNB this wallet would receive if the following were all true:
                      </ContentRow>
											<ContentRow>
												<li>Wallet is excluded from paying the 8% NSM transaction fee.</li>
												<li>0% slippage.</li>
												<li>0 gas cost on transaction.</li>
												<li>Sold 100% of their tokens on the snapshot block.</li>
												<li>Wallet's transaction was the only NSM transaction on the snapshot block.</li>
											</ContentRow>
											<ContentRow>
												This amount was obtained by running a batch call to the pancake swap router, simulating the token sell with the above parameters. The list was spot checked to ensure accuracy.
												<br></br>
											</ContentRow>
											<ContentRow>
												<SmallButton onClick={openGithub}>Github</SmallButton>
											</ContentRow>
											<ContentRow>For additional information visit the NSM github.</ContentRow>
										</TextInline>
								</ContentBlockInset>
							</ContentRow>
						</ContentRow>
					)}

					{holderDeposits_ && (
						<ContentRow>
							<Label>Deposits:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(holderDeposits_, 9)))} NSM</TextInlineConsolas>
						</ContentRow>
					)}

					{remainingNSMdeposit_ && (
						<ContentRow>
							<Label>Remaining Deposits:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(remainingNSMdeposit_, 9)))} NSM</TextInlineConsolas>
						</ContentRow>
					)}

					{maxClaimableFLV && (
						<ContentRow>
							<Label>MAX Claimable:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(maxClaimableFLV, 9)))} FLVR</TextInlineConsolas>
						</ContentRow>
					)}

					{currentClaimableFLV && (
						<ContentRow>
							<Label>Current Claimable:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(currentClaimableFLV, 9)))} FLVR</TextInlineConsolas>
						</ContentRow>
					)}

					{claimedFLV_ && (
						<ContentRow>
							<Label>Claimed:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(claimedFLV_, 9)))} FLVR</TextInlineConsolas>
						</ContentRow>
					)}

					{/*
							{remainingClaimableFLV_ && (
								<ContentRow>
									<Label>Remaining Claimable</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(remainingClaimableFLV_, 9)))}</TextInlineConsolas>
                  <Label> NSM</Label>
                </ContentRow>  
              )}
              */}

					{/*
              {completedDeposits_ && (
                <ContentRow>
                  <Label>Completed Deposits</Label> <TextInlineConsolas>{!completedDeposits_._hex?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}

              {!completedDeposits_ && (
                <ContentRow>
                  <Label>Completed Deposits:</Label> <TextInlineConsolas>{completedDeposits_===0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}
              {completedDeposits_ && (
                <ContentRow>
                  <Label>Completed Deposits:</Label> <TextInlineConsolas>{completedDeposits_===0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}

              {!completedClaims_ && (
                <ContentRow>
                  <Label>Completed Claims:</Label> <TextInlineConsolas>{completedClaims_===0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}
              {completedClaims_ && (
                <ContentRow>
                  <Label>Completed Claims:</Label> <TextInlineConsolas>{completedClaims_===0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}
              */}
				</ContentBlock>
			</SectionRow>
		</Section>
	);
}

export const ContentBlockInset = styled.div`
	box-shadow: inset 1px 1px 3px #999;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: ${Colors.flavors.light.amour};
	border-radius: ${BorderRad.s};
	padding: 16px 16px;
`;

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 60px;
`;

const SmallButton = styled(Button)`
	display: flex;
	justify-content: center;
	min-width: 80px;
	height: unset;
	padding: 8px 24px;
	background-color: ${Colors.flavors.background};
	&:disabled {
		color: ${Colors.Gray['600']};
		cursor: unset;
	}
	&:hover {
		background-color: ${Colors.flavors.main.bysantine};
	}

	&:disabled:hover,
	&:disabled:focus {
		background-color: unset;
		color: unset;
	}
`;
