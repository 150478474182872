import styled from 'styled-components'

import { Fonts } from '../global/styles'

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

export const TextInlineConsolas = styled.span`
  font-family: ${Fonts.Consolas};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

export const TextInline = styled.span`
  font-family: ${Fonts.RobotoSlab};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

export const TextBold = styled(Text)`
  font-weight: 700;
`
