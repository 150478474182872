// hooks/index.ts
import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { Interfaces } from "../components/contracts/Interfaces";
import { Addresses } from "../components/contracts/Addresses";

const contractInterface = Interfaces.nsm.Migrate;
const address = Addresses.nsm.Migrate;

export function GetIceCreamMan() {
  const iceCreamMan: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "iceCreamMan",
    args: [],
  }) ?? [];
  return iceCreamMan;
}

export function GetMyInfo() {
  const [
    snapshotBalance_,
    snapshotAmountOutBNB_,
    holderDeposits_,
    remainingNSMdeposit_,
    maxClaimableFLV,
    currentClaimableFLV,
    claimedFLV_,
    remainingClaimableFLV_,
    completedDeposits_,
    completedClaims_,
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getMyInfo",
    args: [],
  }) ?? [];
  return {
    snapshotBalance_,
    snapshotAmountOutBNB_,
    holderDeposits_,
    remainingNSMdeposit_,
    maxClaimableFLV,
    currentClaimableFLV,
    claimedFLV_,
    remainingClaimableFLV_,
    completedDeposits_,
    completedClaims_,
  };
}

export function CanISell() {
  const canISell: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "canISell",
    args: [],
  }) ?? [];
  return canISell;
}

export function GetRemainingClaimableFLV() {
  const getRemainingClaimableFLV: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getRemainingClaimableFLV",
    args: [],
  }) ?? [];
  return getRemainingClaimableFLV;
}




export function GetTotalClaimableFLV() {
  const getTotalClaimableFLV: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getTotalClaimableFLV",
    args: [],
  }) ?? [];
  return getTotalClaimableFLV;
}

export function GetRemainingNSMdeposit() {
  const getRemainingNSMdeposit: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getRemainingNSMdeposit",
    args: [],
  }) ?? [];
  return getRemainingNSMdeposit;
}

export function GetContractNSMBalance() {
  const contractNSMBalance: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "contractNSMBalance",
    args: [],
  }) ?? [];
  return contractNSMBalance;
}

export function GetCurrentClaimableFLV() {
  const getCurrentClaimableFLV: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getCurrentClaimableFLV",
    args: [],
  }) ?? [];
  return getCurrentClaimableFLV;
}




export function GetHoldersMaxSell() {
  const getHoldersMaxSell: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getHoldersMaxSell",
    args: [],
  }) ?? [];
  return getHoldersMaxSell;
}




export function GetHolderInfo(account: string) {
  const [
    snapshotBalance_,
    snapshotAmountOutBNB_,
    holderDeposits_,
    remainingNSMdeposit_,
    maxClaimableFLV,
    currentClaimableFLV,
    claimedFLV_,
    remainingClaimableFLV_,
    completedDeposits_,
    completedClaims_,
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getHolderInfo",
    args: [account],
  }) ?? [account];
  return {
    snapshotBalance_,
    snapshotAmountOutBNB_,
    holderDeposits_,
    remainingNSMdeposit_,
    maxClaimableFLV,
    currentClaimableFLV,
    claimedFLV_,
    remainingClaimableFLV_,
    completedDeposits_,
    completedClaims_,
  };
}

