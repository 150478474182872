
import { Container, ContentRow, MainContent, Section, SectionRow } from '../components/base/base'
import { TransactionClaimFLV } from '../components/Transactions/MigrateTx'
import { Title } from '../typography/Title'
import styled from 'styled-components'
import React from 'react'
import { Label } from '../typography/Label';
import { TextInlineConsolas } from '../typography/Text';
import { GetInfo } from '../hooks/GetInfo'

export function Claim() {
  const {
    claimsEnabled_,
    depositsEnabled_
   } = GetInfo();
 
  return (
        <Section>
          <SectionRow>
            <Title>Claim</Title>
          </SectionRow>
          <SectionRow>
            {/*
          {!claimsEnabled_ && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}


              {claimsEnabled_ && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}
              */}
            </SectionRow>

            <TransactionClaimFLV />
        </Section>
  )
}

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
