import React, {useEffect, useState} from 'react';
import {useEthers, shortenAddress, useLookupAddress} from '@usedapp/core';
import {Button} from '../base/Button';
import {Colors} from '../../global/styles';
import styled from 'styled-components';

import {AccountModal} from './AccountModal';
import {TextInlineConsolas} from '../../typography/Text';

export const AccountButton = () => {
	const {account, deactivate, activateBrowserWallet} = useEthers();
	const ens = useLookupAddress();
	const [showModal, setShowModal] = useState(false);

	const [activateError, setActivateError] = useState('');
	const {error} = useEthers();
	useEffect(() => {
		if (error) {
			setActivateError(error.message);
		}
	}, [error]);

	const activate = async () => {
		setActivateError('');
		activateBrowserWallet();
	};

	return (
		<Account>
			<ErrorWrapper>{activateError}</ErrorWrapper>
			{showModal && <AccountModal setShowModal={setShowModal} />}
			{account ? (
				<>
					<AccountLabel onClick={() => setShowModal(!showModal)}>
						<TextInlineConsolas>{ens ?? shortenAddress(account)}</TextInlineConsolas>
					</AccountLabel>
					<LoginButton onClick={() => deactivate()}>Disconnect</LoginButton>
					<></>
				</>
			) : (
				<LoginButton onClick={activate}>Connect</LoginButton>
			)}
		</Account>
	);
};

const ErrorWrapper = styled.div`
	color: #ff3960;
	margin-right: 40px;
	margin-left: 40px;
	overflow: auto;
`;

const Account = styled.div`

	display: flex;
	align-items: center;
`;

const LoginButton = styled(Button)`
	&:hover {
		background-color: ${Colors.flavors.main.bysantine};

		&:after {
			transform: scaleX(1);
			color: ${Colors.flavors.main.bysantine};
		}
	}
	background-color: ${Colors.flavors.backgroundLight};
`;

const AccountLabel = styled(Button)`
&:hover {
  background-color: ${Colors.flavors.main.bysantine};

  &:after {
    transform: scaleX(1);
    color: ${Colors.flavors.main.bysantine};
  }
}
background-color: ${Colors.flavors.backgroundLight};
	height: 32px;
	margin-right: -40px;
	padding-right: 40px;
	padding-left: 8px;

	background-color: ${Colors.flavors.backgroundLight};
	font-size: 12px;
`;
