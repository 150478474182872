
import './App.css';

import { Redirect, Route, Switch } from 'react-router'

import { BrowserRouter } from 'react-router-dom'
import { Migrate } from './pages/Migrate';
import { LandingPage } from './components/LandingPage';

// import { TopBar } from './components/TopBar'

function App() {
  return (
    
    <BrowserRouter>
      {/* <TopBar /> */}
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/migrate" component={Migrate} />
        {/* <Route exact path="/snapshot" component={Snapshot} />*/}
        {/* <Route exact path="/claim" component={Claim} />*/}
        {/* <Route exact path="/admin" component={Admin} />*/}
      </Switch>
    </BrowserRouter>

  )
}

export default App;
