

import abi_NotTools_LPDataV3 from '../../abi/NotTools_LPDataV3.json'
import abi_NotTools_MultiSend from '../../abi/NotTools_MultiSend.json'
import abi_NotTools_ERC20Data from '../../abi/NotTools_ERC20Data.json'
import abi_NotTools_BalancesV2 from '../../abi/NotTools_BalancesV2.json'
import abi_NotTools_LivePriveV3 from '../../abi/NotTools_LivePriceV3.json'
import abi_NotTools_FactoryCreate2 from '../../abi/NotTools_FactoryCreate2.json'

import abi_FLV from '../../abi/FLV.json'
import abi_NSM from '../../abi/NSM.json'
import abi_WBNB from '../../abi/WBNB.json'
import abi_WETH from '../../abi/Weth10.json'

import abi_MigrateNSM from '../../abi/MigrateNSM.json'

import abi_Pancake_Pair from '../../abi/Pancake_Pair.json'
import abi_Pancake_Router from '../../abi/Pancake_Router.json'
import abi_Pancake_Factory from '../../abi/Pancake_Factory.json'



export const ABI = {
  NotTools: {
    LPDataV3: abi_NotTools_LPDataV3,
    MultiSend: abi_NotTools_MultiSend,
    ERC20Data: abi_NotTools_ERC20Data,
    BalancesV2: abi_NotTools_BalancesV2,
    LivePriceV3: abi_NotTools_LivePriveV3,
    FactoryCreate2: abi_NotTools_FactoryCreate2,
  },

  FLV: abi_FLV,
  NSM: abi_NSM,
  WBNB: abi_WBNB,
  WETH: abi_WETH,

  nsm: {
    Migrate: abi_MigrateNSM
  },
  
  
  Pancake: {
    Pair: abi_Pancake_Pair,
    Router:  abi_Pancake_Router,
    Factory: abi_Pancake_Factory,
  }
}
