import React from 'react';

import {useEthers, useBlockNumber, useBlockMeta} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section} from '../components/base/base';
import {Label} from '../typography/Label';
import { TextInlineConsolas } from '../typography/Text';
import { SectionRow } from '../components/base/base';

import { helpers } from '../components/helpers/helpers';
import { GetInfo } from '../hooks/GetInfo';

export function BlockStats() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();
	
  
	
	return (
				<SectionRow>
					<ContentBlock>
						<ContentRow>
							<Label>Chain id:</Label> <TextInlineConsolas>{chainId}</TextInlineConsolas>
						</ContentRow>
						<ContentRow>
							<Label>Current block:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(blockNumber))}</TextInlineConsolas>
						</ContentRow>
						{timestamp && (
							<ContentRow>
								<Label>Current block timestamp:</Label> <TextInlineConsolas>{timestamp.toLocaleString()}</TextInlineConsolas>
							</ContentRow>
						)}
					</ContentBlock>
				</SectionRow>
	);
}