// hooks/index.ts
import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { Interfaces } from "../components/contracts/Interfaces";
import { Addresses } from "../components/contracts/Addresses";

const contractInterface = Interfaces.nsm.Migrate;
const address = Addresses.nsm.Migrate;

//export function GetIceCreamMan() {
//  const iceCreamMan: any = useContractCall({
//    abi: contractInterface,
//    address: address,
//    method: "iceCreamMan",
//    args: [],
//  }) ?? [];
//  return iceCreamMan;
//}

export function GetAddresses() {
  const [
    flv,
    creamery,
    nsm,
    router,
    wrappedNative,
    iceCreamMan_,
    pendingIceCreamMan_
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: 'getAddresses',
    args: [],
  }) ?? [];
  return {
    flv,
    creamery,
    nsm,
    router,
    wrappedNative,
    iceCreamMan_,
    pendingIceCreamMan_
  };
}



export function GetInfo() {
  const [
    snapshot_block,
    snapshot_timestamp,
    snapshot_date,
    snapshot_reservesNSM,
    snapshot_reservesBNB,
    globalTotal_snapshotBalance_,
    globalTotal_snapshotAmountOutBNB_,
    globalTotal_deposits_,
    globalTotal_claims_,
    flvPerNativeCoin_,
    claimsEnabled_,
    depositsEnabled_,
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getInfo",
    args: [],
  }) ?? [];
  return {
    snapshot_block,
    snapshot_timestamp,
    snapshot_date,
    snapshot_reservesNSM,
    snapshot_reservesBNB,
    globalTotal_snapshotBalance_,
    globalTotal_snapshotAmountOutBNB_,
    globalTotal_deposits_,
    globalTotal_claims_,
    flvPerNativeCoin_,
    claimsEnabled_,
    depositsEnabled_,
  };
}


