import {ContentBlock, ContentRow, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';

import {TextInlineConsolas} from '../typography/Text';
import { Link } from '../components/base/Link';

import {GetInfo} from '../hooks/GetInfo';
import {helpers} from '../components/helpers/helpers';
import {TextInline} from '../typography/Text';
import styled from 'styled-components';
import {Colors, BorderRad} from '../global/styles';

export function MigrationStats() {
	const {globalTotal_snapshotBalance_, globalTotal_deposits_, globalTotal_claims_, flvPerNativeCoin_, claimsEnabled_, depositsEnabled_} = GetInfo();
  
  const percentMigrated = (Math.round((Number(globalTotal_deposits_ / 10 ** 9) / Number(globalTotal_snapshotBalance_ / 10 ** 9)) * 100_000) / 1_000)
  
  

  const InnerThing = styled.div`
    box-shadow: 1px 0px 1px 0px #666666;
    display: flex;
    flex-direction: column;
    height: 20px;
    width: ${percentMigrated<1?1:percentMigrated}%;
    background-color: ${Colors.flavors.main.steelPink};
    border-radius: ${BorderRad.s};
    margin: 0px;
    padding: 0px;
    transition: width 1s ease-in-out;
    borderradius: inherit;
    textalign: right;
  `
  

	return (
		<Section>
			<SectionRow>
				<Title>Migration Stats</Title>
			</SectionRow>

			<SectionRow>
				<ContentBlock>
					{/*
           {globalTotal_snapshotAmountOutBNB_ && (
             <ContentRow>
               <Label>Holder AmountOutBNB Total: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_snapshotAmountOutBNB_._hex/(10**9)))}</TextInlineConsolas>{' '}
             </ContentRow>
           )}
           */}
           	{percentMigrated && (
					<ContentRow>
						<SectionRow>
							<Thing>
								<InnerThing>
                </InnerThing>
							</Thing>
						</SectionRow>
					</ContentRow>
					)}

					{(
						<ContentRow>
							<Label>Percent Migrated: </Label> <TextInlineConsolas>{(percentMigrated).toFixed(2)}%</TextInlineConsolas>{' '}
						</ContentRow>
					)}

					{globalTotal_claims_ && (
						<ContentRow>
							<Label>Total Claims: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_claims_._hex / 10 ** 9))} FLVR</TextInlineConsolas>{' '}
						</ContentRow>
					)}

					{flvPerNativeCoin_ && (
						<ContentRow>
							<Label>Rate: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(flvPerNativeCoin_._hex / 10 ** 9))} FLVR per BNB</TextInlineConsolas>{' '}
						</ContentRow>
					)}

          

					{globalTotal_deposits_ && (
						<ContentRow>
							<Label>Total Deposits: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_deposits_._hex / 10 ** 9))} NSM</TextInlineConsolas>{' '}
						</ContentRow>
					)}

					{globalTotal_snapshotBalance_ && (
						<ContentRow>
							<Label>Tokens Eligible for Migration: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Math.round(Number(globalTotal_snapshotBalance_._hex / 10 ** 9)))} NSM</TextInlineConsolas>{' '}
              
							<ContentRow><br></br>
				
        <Label>Migration Contract Address:</Label><Link href="https://bscscan.com/address/0xF1a4550Cd1d06b1e4D7c34fE49dA26D2E6253C55"> <TextInlineConsolas>0xF1a4550Cd1d06b1e4D7c34fE49dA26D2E6253C55</TextInlineConsolas></Link>
				
             
							</ContentRow>
							<ContentRow>
								<ContentBlockInset>
									<ContentRow />
									<TextInline>Migration stats exclude tokens in LP & burnt tokens.</TextInline>
									<ContentRow />
									<TextInline>The marketing wallet & a majority of dev tokens will be donated to the migration.</TextInline>
									<ContentRow />
									<TextInline>To avoid paying any transfer taxes on the Migration, tokens will be sold from the NSM marketing wallet, which is excluded from paying taxes.</TextInline>
									<ContentRow />
								</ContentBlockInset>
							</ContentRow>
						</ContentRow>
					)}
				</ContentBlock>
			</SectionRow>
		</Section>
	);
}

export const Thing = styled.div`
	box-shadow: inset 1px 1px 3px #777777;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 20px;
	background-color: ${Colors.flavors.light.fuchsiaPink};
	border-radius: ${BorderRad.s};
	transition: width 1s ease-in-out;
`;

export const ContentBlockInset = styled.div`
	box-shadow: inset 1px 1px 3px #999;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: ${Colors.flavors.light.amour};
	border-radius: ${BorderRad.s};
	padding: 16px 16px;
`;


