
import {ContentBlock, ContentRow, Section,
     SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';

import { TextInlineConsolas } from '../typography/Text';

import { GetInfo } from '../hooks/GetInfo';
import { helpers } from '../components/helpers/helpers';

export function Snapshot() {

	const {snapshot_block,
    snapshot_timestamp,
    snapshot_date,
     snapshot_reservesNSM,
     snapshot_reservesBNB,
    } = GetInfo();
  
	
	return (
				<Section>
					<SectionRow>
						<Title>Snapshot</Title>
					</SectionRow>

					<SectionRow>            
						<ContentBlock>
							{snapshot_block && (
								<ContentRow>
									<Label> Block: </Label> <TextInlineConsolas>{helpers.numberWithCommas(snapshot_block)}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{snapshot_timestamp && (
								<ContentRow>
									<Label>Timestamp: </Label> <TextInlineConsolas>{snapshot_timestamp}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{snapshot_date && (
								<ContentRow>
									<Label>Date: </Label> <TextInlineConsolas>{snapshot_date}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
			        {snapshot_reservesNSM && (
								<ContentRow>
									<Label>LP Reserves NSM: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Math.round(Number(snapshot_reservesNSM._hex/(10**9))))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{snapshot_reservesBNB && (
								<ContentRow>
									<Label>LP Reserves BNB: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(snapshot_reservesBNB._hex/(10**18)))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}

						</ContentBlock>
					</SectionRow>


				</Section>
	);
}