import React from 'react';

import {Container, MainContent} from '../components/base/base';
import {Instructions} from './Instructions';
import {Approve} from './Approve';
import {Deposit} from './Deposit';
import {MyInfo} from './MyInfo';
import {Admin} from './Admin';
import {Snapshot} from './Snapshot';
import {MigrationStats} from './MigrationStats';
import {Page} from '../components/base/base';
import {GlobalStyle} from '../global/GlobalStyle';
import {PageHeader} from '../components/PageHeader';
import {NotificationsList} from '../components/Transactions/History';
import {Claim} from './Claim';
import { GetAddresses } from '../hooks/GetInfo';
import { useEthers } from '@usedapp/core';

export function Migrate() {
  const { iceCreamMan_ } = GetAddresses()
  const { account } = useEthers()
  

	return (
		<Page>
			<GlobalStyle />
			<PageHeader />
			<MainContent>
				<Container>
					<Instructions />
					<Snapshot />
					<MigrationStats />
					<MyInfo />
					<Approve />
					<Deposit />
					<Claim />
          {iceCreamMan_==account?
					<Admin />
          :''
        }
				</Container>
			</MainContent>
			<NotificationsList />
		</Page>
	);
}
