import React from 'react'

import { Container, ContentRow, MainContent, Section, SectionRow } from '../components/base/base'
import { Title } from '../typography/Title'
import styled from 'styled-components'
import { BlockStats } from './BlockStats'

import {
  TransactionMigrateClaimedNSM,
  TransactionToggleDeposits,
  TransactionToggleClaims,
  TransferContributedBNB,
  SwapOut
} from '../components/Transactions/MigrateTx'
import { GetInfo } from '../hooks/GetInfo';
import { Label } from '../typography/Label';
import { TextInlineConsolas } from '../typography/Text';

export function Admin() {
  const { 
    snapshot_block,
    snapshot_timestamp,
    snapshot_date,
    snapshot_reservesNSM,
    snapshot_reservesBNB,
    globalTotal_snapshotBalance_,
    globalTotal_snapshotAmountOutBNB_,
    globalTotal_deposits_,
    globalTotal_claims_,
    flvPerNativeCoin_,
    claimsEnabled_,
    depositsEnabled_
  } = GetInfo()
  return (
        <Section>
          <SectionRow>
            <Title>Admin</Title>
          </SectionRow>
          <SectionRow>

            {/*
              {!claimsEnabled_ && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}


              {claimsEnabled_ && (
                <ContentRow>
                  <Label>Claims Enabled</Label> <TextInlineConsolas>{claimsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}


              {!depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'✔️':'❌'}</TextInlineConsolas>
                </ContentRow>
              )}
              
              {depositsEnabled_ && (
                <ContentRow>
                  <Label>Deposits Enabled:</Label> <TextInlineConsolas>{depositsEnabled_._hex==0x00?'❌':'✔️'}</TextInlineConsolas>
                </ContentRow>
              )}
              */}
          </SectionRow>

          <BlockStats />
          <SectionRow>
            <TransactionToggleDeposits />
            </SectionRow>
          <SectionRow>
            <TransactionToggleClaims />
            </SectionRow>
          <SectionRow>
            <TransactionMigrateClaimedNSM />
            </SectionRow>
            {/* <TransferContributedBNB /> */}
            {/* <SwapOut /> */}
            {/* <TransactionAcceptIceCreamManOPI /> */}
        </Section>
  )
}

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`
