import styled from 'styled-components';

import {Images} from './Images';

export function LandingPage() {
	return (
		<ToMain href="/migrate">
			<img src={Images.migrateNSM} width="100%" height="100%" />
		</ToMain>
	);
}
const ToMain = styled.a`
	display: flex;
	flex-direction: column;
	width: fit-content;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
`;
